import React from "react"
import Link from "gatsby-link"
import { useStaticQuery, graphql } from "gatsby"

import style from "./style.module.scss"

const Template = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className={style.banner}>
        <div className={style.bannerContent}>
          Text or call <strong>(702) 912-3144</strong>
        </div>
      </div>
      <header className={style.header}>
        <div className={style.headerContent}>
          <h2 style={{ margin: 0 }}>
            <Link to="/">
              <strong>Reliable</strong> Pet Care
            </Link>
          </h2>
          <div className={style.links}>
            <Link to="/about">About</Link>
            <Link to="/serices">Services</Link>
          </div>
        </div>
      </header>
      <div className={style.template}>
        <main className={style.templateContent}>{children}</main>
      </div>
      <div className={style.footer}>
        <div className={style.footerContent}>Reliable Pet Care</div>
      </div>
    </>
  )
}

export default Template
